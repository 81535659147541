<section class="is-position-relative mb--7px-desktop">
  <svg-wave class="is-position-absolute is-top-93px-mobile is-top-166px-tablet-only is-top-113px-desktop is-left-0 is-right-0"></svg-wave>
  <h1
    class="is-position-absolute is-top-0 is-left-0-mobile is-left-10px-tablet is-right-0 has-text-centered-mobile is-size-2-mobile is-size-1-tablet is-line-height-110 is-family-secondary has-text-weight-heavy has-text-deep-sea-green mt-2">
    <ng-container i18n>Dein Online-Yogastudio</ng-container>
  </h1>
  <div
    class="is-hidden-mobile is-position-absolute is-top-85px is-left-0-mobile is-left-10px-tablet is-width-528px-tablet is-size-5-and-half has-text-kokoda">
    <ng-container *ngTemplateOutlet="text"></ng-container>
  </div>
  <a [href]="(siteParams$ | async)?.cta_button?.button_url || '/prices'"
    class="is-hidden-mobile is-hidden-tablet-only button is-mandy-pink is-rounded has-text-weight-semibold is-position-absolute is-top-200px is-left-10px-tablet px-6">
    {{ (siteParams$ | async)?.cta_button?.button_text || defaultCTAText() }}
  </a>

  <!-- app-disrupter is visible just for German version -->
  <app-disrupter largeText="Jetzt" smallText="Rabatt sichern"
    class="is-position-absolute is-top-105px-mobile is-top-120px-tablet-only is-top-35px-desktop is-left-16px-mobile is-right-30px-tablet-only is-right-95px-desktop"
    i18n-largeText i18n-smallText>
  </app-disrupter>
  @if (locale === 'de') {
  <img ngSrc="production/uploads/components/homepage/top-section/header.jpg" priority
    ngSrcset="340w, 680w, 460w, 920w, 580w, 1140w, 730w, 1460w, 1170w, 2340w"
    sizes="(max-width: 730px) 100vw, (max-width: 1170px) 730px, 1170px" width="2340" height="1400" alt="Home Header"
    class="is-width-100 has-margin-top-100px-mobile has-margin-top-166px-tablet-only has-margin-top-113px-desktop" />
  <div class="is-hidden-desktop is-flex is-justify-content-center has-background-white p-1">
    <app-ausgezeichnet-bar></app-ausgezeichnet-bar>
  </div>
  }
  @else {
  <img ngSrc="production/uploads/components/homepage/top-section/header-EN.jpg" priority
    ngSrcset="340w, 680w, 460w, 920w, 580w, 1140w, 730w, 1460w, 1170w, 2340w"
    sizes="(max-width: 730px) 100vw, (max-width: 1170px) 730px, 1170px" width="2340" height="1400" alt="Home Header"
    class="is-width-100 has-margin-top-100px-mobile has-margin-top-166px-tablet-only has-margin-top-113px-desktop" />
  }
  <div class="is-hidden-tablet has-text-centered has-text-kokoda is-size-5-and-half py-4 px-6">
    <ng-container *ngTemplateOutlet="text"></ng-container>
  </div>
</section>

<ng-template #text i18n>
  YogaEasy ist dein persönliches Online-Yogastudio für zu Hause oder unterwegs.
  Mit YogaEasy kannst du ganz bequem Yoga üben - wann und wo
  immer du willst.
</ng-template>