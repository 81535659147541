import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { VideoTeaser } from 'src/app/api/models/video-teaser';
import { Viewport } from 'src/app/core/core.state';

@Component({
  selector: 'app-videos-section',
  templateUrl: './videos-section.component.html',
  styleUrls: ['./videos-section.component.sass'],
})
export class VideosSectionComponent implements OnInit {
  public viewport$: Observable<Viewport>;

  constructor(
    private store: Store,
    @Inject(LOCALE_ID) public locale: string,
  ) {}

  ngOnInit(): void {
    this.viewport$ = this.store.select(state => state.core.viewport);
  }

  public videoTeasersDE = [
    {
      id: 21252,
      slug: 'sanfter-morgen-flow-mit-wanda',
      images: {
        webp: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/21252/webp_wanda-badwal-sanfter-morgen-flow-still.jpg.webp',
        i377_212:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21252/i377_212_wanda-badwal-sanfter-morgen-flow-still.jpg',
        i377_212_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21252/i377_212_webp_wanda-badwal-sanfter-morgen-flow-still.jpg.webp',
        thumb:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21252/thumb_wanda-badwal-sanfter-morgen-flow-still.jpg',
        thumb_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21252/thumb_webp_wanda-badwal-sanfter-morgen-flow-still.jpg.webp',
        big: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/21252/big_wanda-badwal-sanfter-morgen-flow-still.jpg',
        fill_873_491:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21252/fill_873_491_wanda-badwal-sanfter-morgen-flow-still.jpg',
        fill_873_491_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21252/fill_873_491_webp_wanda-badwal-sanfter-morgen-flow-still.jpg.webp',
      },
      title: 'Sanfter Morgen-Flow mit Wanda',
      teacher: 'Wanda Badwal',
      published_at: '2024-04-25T05:00:00.000+02:00',
      levels: [
        {
          number: 1,
          label: 'Sportliche Anfänger',
        },
      ],
      rating_count: 691,
      rating: 5.0,
      comment_count: 70,
      style: 'Hatha',
      effort: 3,
      path: '/videos/sanfter-morgen-flow-mit-wanda',
      duration_in_words: '24 Min.',
      short_description:
        'Du bringst Länge in deinen Rücken und Weite in deinen Herzraum.',
      is_new: false,
      image_alt: 'Wanda Badwal sanfter Morgen-Flow',
    },
    {
      id: 3683,
      slug: 'tutorial-box-breath-fuer-mehr-fokus',
      images: {
        webp: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/3683/webp_box_breath_tutorial_still.jpg.webp',
        i377_212:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3683/i377_212_box_breath_tutorial_still.jpg',
        i377_212_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3683/i377_212_webp_box_breath_tutorial_still.jpg.webp',
        thumb:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3683/thumb_box_breath_tutorial_still.jpg',
        thumb_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3683/thumb_webp_box_breath_tutorial_still.jpg.webp',
        big: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/3683/big_box_breath_tutorial_still.jpg',
        fill_873_491:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3683/fill_873_491_box_breath_tutorial_still.jpg',
        fill_873_491_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3683/fill_873_491_webp_box_breath_tutorial_still.jpg.webp',
      },
      title: 'Tutorial: Box Breath für mehr Fokus',
      teacher: 'Tina Scheid',
      published_at: '2022-01-31T05:05:00.000+01:00',
      levels: [
        {
          number: -1,
          label: 'Für alle',
        },
      ],
      rating_count: 328,
      rating: 5.0,
      comment_count: 13,
      style: 'Atemübungen',
      effort: 0,
      path: '/videos/tutorial-box-breath-fuer-mehr-fokus',
      duration_in_words: '1 Min.',
      short_description:
        'Tina Scheid zeigt dir die Box-Atmung (Box Breath), die dir hilft, wenn du im Alltag mal unkonzentriert bist und dich wieder neu fokussieren möchtest. ',
      is_new: false,
      image_alt: 'Box Breath für mehr Fokus – Tina Scheid',
    },
    {
      id: 4392,
      slug: 'mit-rueckbeugen-angstfrei-nach-vorne-schauen',
      images: {
        webp: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/webp_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg.webp',
        i377_212:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/i377_212_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg',
        i377_212_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/i377_212_webp_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg.webp',
        thumb:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/thumb_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg',
        thumb_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/thumb_webp_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg.webp',
        big: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/big_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg',
        fill_873_491:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/fill_873_491_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg',
        fill_873_491_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/fill_873_491_webp_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg.webp',
      },
      title: 'Mit Rückbeugen angstfrei nach vorne schauen',
      teacher: 'Moritz Ulrich',
      published_at: '2022-11-17T05:14:00.000+01:00',
      levels: [
        {
          number: 2,
          label: 'Mittelstufe',
        },
      ],
      rating_count: 434,
      rating: 5.0,
      comment_count: 76,
      style: 'Jivamukti',
      effort: 4,
      path: '/videos/mit-rueckbeugen-angstfrei-nach-vorne-schauen',
      duration_in_words: '43 Min.',
      short_description:
        'Eine Allround-Sequenz mit Fokus auf Rückbeugen, die dich unterstützen können, bedingungslos zu lieben und dich lieben zu lassen.',
      is_new: false,
      image_alt: 'Mit Rückbeugen angstfrei nach vorne schauen – Moritz Ulrich',
    },
    {
      id: 3840,
      slug: 'yoga-gegen-aengste-und-stress',
      images: {
        webp: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/3840/webp_yoga_angst_stress_still.jpg.webp',
        i377_212:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3840/i377_212_yoga_angst_stress_still.jpg',
        i377_212_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3840/i377_212_webp_yoga_angst_stress_still.jpg.webp',
        thumb:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3840/thumb_yoga_angst_stress_still.jpg',
        thumb_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3840/thumb_webp_yoga_angst_stress_still.jpg.webp',
        big: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/3840/big_yoga_angst_stress_still.jpg',
        fill_873_491:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3840/fill_873_491_yoga_angst_stress_still.jpg',
        fill_873_491_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3840/fill_873_491_webp_yoga_angst_stress_still.jpg.webp',
      },
      title: 'Yoga gegen Ängste und Stress',
      teacher: 'Gabriela Bozic',
      published_at: '2022-03-31T05:05:00.000+02:00',
      levels: [
        {
          number: 1,
          label: 'Sportliche Anfänger',
        },
      ],
      rating_count: 536,
      rating: 4.5,
      comment_count: 109,
      style: 'Vinyasa Flow / Power Yoga',
      effort: 2,
      path: '/videos/yoga-gegen-aengste-und-stress',
      duration_in_words: '37 Min.',
      short_description:
        'Verschiedene Seitdehnungen und Vorbeugen geben dir mehr Erdung, Zuversicht und Ruhe. Die Praxis gibt dir das Gefühl des inneren Friedens zurück.',
      is_new: false,
      image_alt: 'yoga_gegen_angst_stress',
    },
  ] as Array<VideoTeaser>;

  public videoTeasersEN = [
    {
      id: 21244,
      slug: 'unhook-from-worry-and-embrace-inner-peace',
      images: {
        webp: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/21244/webp_0_lddxjk6e.jpg.webp',
        i377_212:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21244/i377_212_0_lddxjk6e.jpg',
        i377_212_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21244/i377_212_webp_0_lddxjk6e.jpg.webp',
        thumb:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21244/thumb_0_lddxjk6e.jpg',
        thumb_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21244/thumb_webp_0_lddxjk6e.jpg.webp',
        big: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/21244/big_0_lddxjk6e.jpg',
        fill_873_491:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21244/fill_873_491_0_lddxjk6e.jpg',
        fill_873_491_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21244/fill_873_491_webp_0_lddxjk6e.jpg.webp',
      },
      title: 'Unhook from worry and embrace inner peace',
      teacher: 'Esther Ekhart',
      published_at: '2024-03-23T04:00:00.000+01:00',
      levels: [
        {
          number: -1,
          label: 'For All',
        },
      ],
      rating_count: 0,
      rating: 0.0,
      comment_count: 0,
      style: 'Meditation',
      effort: 0,
      path: '/videos/unhook-from-worry-and-embrace-inner-peace',
      duration_in_words: '22 min.',
      short_description:
        "This guided meditation begins by calming the nervous system through mindful breathing exercises, setting the stage for a deeper connection with your inner self. As you progress, you are gently guided to relax your body and release the day's tensions, accompanied by the reassuring reminder that, in this moment of meditation, there are no problems to solve.The meditation then transitions to exploring the essence of your being – the conscious observer of all these sensations...",
      is_new: false,
      image_alt: 'Yoga Video Unhook from worry and embrace inner peace',
    },
    {
      id: 7172,
      slug: '3in1-practice',
      images: {
        webp: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/7172/webp_0_s8ypxm1q.jpg.webp',
        i377_212:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/7172/i377_212_0_s8ypxm1q.jpg',
        i377_212_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/7172/i377_212_webp_0_s8ypxm1q.jpg.webp',
        thumb:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/7172/thumb_0_s8ypxm1q.jpg',
        thumb_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/7172/thumb_webp_0_s8ypxm1q.jpg.webp',
        big: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/7172/big_0_s8ypxm1q.jpg',
        fill_873_491:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/7172/fill_873_491_0_s8ypxm1q.jpg',
        fill_873_491_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/7172/fill_873_491_webp_0_s8ypxm1q.jpg.webp',
      },
      title: '3 in 1 practice',
      teacher: 'David Kam',
      published_at: '2021-09-24T04:00:00.000+02:00',
      levels: [
        {
          number: 1,
          label: 'Athletic Beginner',
        },
        {
          number: 2,
          label: 'Intermediate',
        },
      ],
      rating: 0.0,
      comment_count: 0,
      style: 'Vinyasa Flow',
      effort: 0,
      path: '/videos/3in1-practice',
      duration_in_words: '50 min.',
      short_description:
        'A complete practice of breath work, movement and meditation. A strong practice guaranteed to energize and revitalize...',
      is_new: false,
      image_alt: 'Yoga Video 3 in 1 practice',
    },
    {
      id: 5957,
      slug: 'perfect-45-flow',
      images: {
        webp: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/5957/webp_0_l4tkw5dm.jpg.webp',
        i377_212:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/5957/i377_212_0_l4tkw5dm.jpg',
        i377_212_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/5957/i377_212_webp_0_l4tkw5dm.jpg.webp',
        thumb:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/5957/thumb_0_l4tkw5dm.jpg',
        thumb_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/5957/thumb_webp_0_l4tkw5dm.jpg.webp',
        big: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/5957/big_0_l4tkw5dm.jpg',
        fill_873_491:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/5957/fill_873_491_0_l4tkw5dm.jpg',
        fill_873_491_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/5957/fill_873_491_webp_0_l4tkw5dm.jpg.webp',
      },
      title: 'Perfect 45 Flow',
      teacher: 'Tashi Dawa',
      published_at: '2022-12-20T04:00:00.000+01:00',
      levels: [
        {
          number: -1,
          label: 'For All',
        },
      ],
      rating_count: 0,
      rating: 0.0,
      comment_count: 0,
      style: 'Hatha',
      effort: 0,
      path: '/videos/perfect-45-flow',
      duration_in_words: '46 min.',
      short_description:
        'Join Tashi for a whole body 45min flow practice. This sequence is based around sun salutations so may recognize and flow into your own style of transitions or modifications...',
      is_new: false,
      image_alt: 'Yoga Video Perfect 45 Flow',
    },
    {
      id: 4392,
      slug: 'mit-rueckbeugen-angstfrei-nach-vorne-schauen',
      images: {
        webp: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/webp_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg.webp',
        i377_212:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/i377_212_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg',
        i377_212_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/i377_212_webp_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg.webp',
        thumb:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/thumb_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg',
        thumb_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/thumb_webp_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg.webp',
        big: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/big_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg',
        fill_873_491:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/fill_873_491_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg',
        fill_873_491_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/fill_873_491_webp_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg.webp',
      },
      title: 'Mit Rückbeugen angstfrei nach vorne schauen',
      teacher: 'Moritz Ulrich',
      published_at: '2022-11-17T05:14:00.000+01:00',
      levels: [
        {
          number: 2,
          label: 'Mittelstufe',
        },
      ],
      rating_count: 434,
      rating: 5.0,
      comment_count: 76,
      style: 'Jivamukti',
      effort: 4,
      path: '/videos/mit-rueckbeugen-angstfrei-nach-vorne-schauen',
      duration_in_words: '43 Min.',
      short_description:
        'Eine Allround-Sequenz mit Fokus auf Rückbeugen, die dich unterstützen können, bedingungslos zu lieben und dich lieben zu lassen.',
      is_new: false,
      image_alt: 'Mit Rückbeugen angstfrei nach vorne schauen – Moritz Ulrich',
    },
  ] as Array<VideoTeaser>;
}
